
.step {
  border-bottom: 1px solid var(--colors-staticBorderDefault);
  padding-bottom: 40px;

  h2 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.4;
    margin: 0;
  }
}

.stepContent {
  padding: 10px 0;
}