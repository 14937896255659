
.checkoutForm {
  display: grid;
  grid-template-columns: 1fr fit-content(50%);
  gap: 32px;
  grid-template-areas: "heading heading" "form cart";
  max-width: 1000px;
  margin: auto;
  padding: 40px 40px 100px;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    max-width: 600px;
  }
}

.heading {
  grid-area: heading;
}

.form {
  grid-area: form;
  display: flex;
  flex-flow: column;
  gap: 40px;
}

.cart {
  grid-area: cart;
}